import React from 'react';
import { connect } from 'react-redux';
import { Drawer, Form, Button, Col, Row, Input, Select, Radio, Switch } from 'antd';
import { compare } from 'natural-orderby';
import moment from 'moment';

import InputMask from '../UI/InputMask';
import { DatePicker } from '../UI/DatePicker/Locale';
import Tip from '~/components/Tip';

const { Option } = Select;

class CerticatesForm extends React.Component {
    state = {
        default: {
            id: null,
            customer_id: null,
            owner_name: null,
            nature: null,
            type: null,
            phone: null,
            emission_date: null,
            due_date: null,
            password:null,
            bird: false,
        },
        activeForm:{}
    };

    onCreate = () => {
        const { form } = this.props;
        const context = this;

        form.validateFields((err, values) => {

            if (err) {
                return;
            }

            this.props.registerCertificate(values)
            .then(response => {
                form.resetFields();
                context.props.onClose();
                context.setState({activeForm: {}});
            });
        });
        
    }

    onSave = () => {
        const { form } = this.props;
        const context = this;

        form.validateFields((err, values) => {

            if (err) {
                return;
            }

            this.props.saveCertificate(this.props.data.id, {
                ...values,
                status: this.props.data.status
            })
            .then(response => {
                form.resetFields();
                context.props.onClose();
                context.setState({activeForm: {}});
            });
        });
        
    }

    render() {
        const { getFieldDecorator, getFieldValue } = this.props.form;
        const { customers } = this.props;

        const data = {
            ...this.state.default,
            ...this.props.data,
            ...this.state.activeForm
        }

        const currentCustomers = data.id
            ? customers 
            : customers.filter(({ enabled, inactived_at, is_customer }) => {
                return enabled && inactived_at === null && is_customer;
            });
            
        return (
        <div>
            <Drawer
                title={(data.id)? 'Editar Certificado': 'Cadastrar Certificado'}
                width={600}
                placement="right"
                onClose={() => {
                    this.props.form.resetFields();
                    this.props.onClose();
                    this.setState({activeForm: {}});
                }}
                maskClosable={false}
                visible={!!this.props.visible}
                destroyOnClose={true}
                style={{
                    height: '100%',
                    overflow: 'auto',
                    paddingBottom: 53,
                }}
            >
            <Form layout="vertical">
                <Row gutter={16}>
                    <Col span={12}>
                    <Form.Item label="Natureza">
                        {getFieldDecorator('nature', {
                                initialValue: data.nature,
                                rules: [{ required: true, message: 'Por favor selecione uma opção!' }],
                            })(
                            <Radio.Group onChange={(e) => this.setState({activeForm: {...data, nature: e.target.value}})}>
                                <Radio.Button value="PF">Pessoa Física</Radio.Button>
                                <Radio.Button value="PJ">Pessoa Jurídica</Radio.Button>
                            </Radio.Group>
                        )}
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label="Tipo de certificado">
                            {getFieldDecorator('type', {
                                initialValue: data.type,
                                rules: [{ required: true, message: 'Por favor selecione uma opção!' }],
                            })(
                                <Select
                                    showSearch
                                    placeholder="Selecione uma opção"
                                    optionFilterProp="children"
                                    filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                >
                                <Option value="E-CPF A1">E-CPF A1</Option>
                                <Option value="E-CPF A3">E-CPF A3</Option>
                                <Option value="E-CNPJ A1">E-CNPJ A1</Option>
                                <Option value="E-CNPJ A3">E-CNPJ A3</Option>
                            </Select>
                            )}
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item label="Empresa">
                            {getFieldDecorator('customer_id', {
                                initialValue: data.id? data.customer_id==null?'not_have':data.customer_id:null,
                                rules: [{ required: true, message: 'Por favor selecione uma opção!' }],
                            })(
                                <Select
                                    showSearch
                                    placeholder="Selecione uma empresa"
                                    optionFilterProp="children"
                                    disabled={(data.id!==null)}
                                    onChange={(val) => this.setState({default: {...this.state.default, customer_id: val}})}
                                    filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                >
                                <Option value="not_have">Não possui ou não é cliente</Option>
                                {currentCustomers.map(row => (
                                    <Option
                                        value={row.id}
                                        key={row.id}
                                    >
                                        {row.cod ? `${row.cod} - ${row.name}` : row.name}
                                    </Option>
                                ))}
                              </Select>
                            )}
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item label="Nome do Sócio" required={data.nature === 'PF'}>
                        {getFieldDecorator('owner_name', {
                            initialValue: data.owner_name,
                            rules: data.nature === 'PF' ? [{ required: true, message: 'Por favor insira o nome do Sócio!' }] : [],
                        })(<Input placeholder="Insira o nome do Sócio" />)}
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item label="Emissão">
                        {getFieldDecorator('emission_date', {
                            initialValue: data.id && data.emission_date ? moment(data.emission_date, 'YYYY-MM-DD'): null,
                            rules: data.status !== 'pendent' || (data.status === 'pendent' && !!getFieldValue('due_date'))
                                ? [{ required: true, message: 'Por favor escolha uma data!' }]
                                : [],
                        })(
                            <DatePicker/>
                        )}
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item label="Vencimento">
                        {getFieldDecorator('due_date', {
                            initialValue: data.id && data.emission_date ? moment(data.due_date, 'YYYY-MM-DD'): null,
                            rules: data.status !== 'pendent'|| (data.status === 'pendent' && !!getFieldValue('emission_date'))
                                ? [{ required: true, message: 'Por favor escolha uma data!' }]
                                : [],
                        })(
                            <DatePicker/>
                        )}
                        </Form.Item>
                    </Col>
                    {(data.customer_id === 'not_have' || data.customer_id === null || data.phone !==null) && (
                    <Col span={8}>
                        <Form.Item label="Telefone/Celular">
                        {getFieldDecorator('phone', {
                            initialValue: data.phone_formated
                        })(<InputMask 
                                mask="(99) 9999tt999?" 
                                formatChars={{"9": "[0-9]", "t": "[0-9\-]", "?": "[0-9 ]"}} 
                                maskChar={null}
                                placeholder="Insira o número de telefone ou celular" 
                            />)}
                        </Form.Item>
                    </Col>
                    )}
                    <Col span={8}>
                        <Form.Item label="Agendado para">
                            {getFieldDecorator('scheduled_at', {
                                initialValue: data.scheduled_at ? moment(data.scheduled_at, 'YYYY-MM-DD') : null
                            })(
                                <DatePicker />
                            )}
                        </Form.Item>
                    </Col>
                    {/* <Col span={8}>
                        <Form.Item label="Valor">
                            {getFieldDecorator('amount', {
                                initialValue: data.amount
                            })(
                                <InputCurrency />
                            )}
                        </Form.Item>
                    </Col> */}
                    <Col span={24}>
                        <Form.Item label="Senha Cadastrada">
                        {getFieldDecorator('password', {
                            initialValue: data.password,
                        })(<Input placeholder="Insira a senha cadastrada" />)}
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item label="Bloco de notas">
                        {getFieldDecorator('notes', {
                            initialValue: data.notes,
                        })(<Input.TextArea rows={4} placeholder="Digite suas anotações" />)}
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item label={<span>BIRD <Tip>Processo simplificado de renovação do certificado digital que dispensa a necessidade de validação presencial ou por videoconferência, garantindo segurança e praticidade.</Tip></span>}>
                            {getFieldDecorator('bird', {
                                valuePropName: 'checked',
                                initialValue: data.bird
                            })(
                                <Switch />
                            )}
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
            <div
                style={{
                position: 'absolute',
                bottom: 0,
                width: '100%',
                borderTop: '1px solid #e8e8e8',
                padding: '10px 16px',
                textAlign: 'right',
                left: 0,
                background: '#fff',
                borderRadius: '0 0 4px 4px',
                }}
            >
                <Button
                style={{
                    marginRight: 8,
                }}
                onClick={this.props.onClose}
                >
                Cancelar
                </Button>
                {(data.id)?
                <Button onClick={this.onSave} type="primary">Salvar</Button>
                :
                <Button onClick={this.onCreate} type="primary">Cadastrar</Button>
                }
            </div>
            </Drawer>
        </div>
        );
    }
}


const mapDispatchProps = dispatch => {
    return{
        registerCertificate: (data) => dispatch({
        type: 'REGISTER_CERTIFICATE',
        payload: {
          request:{
            method: 'post',
            url:'/certificates',
            data: data
          }
        }
      }),
      saveCertificate: (id, data) => dispatch({
        type: 'SAVE_CERTIFICATE',
        payload: {
          request:{
            method: 'post',
            url:`/certificates/${id}`,
            data: data
          }
        }
      })
    }
}

const mapStateToProps = state => {
    return {
        customers: state.customers.list.filter(row => row.enabled === 1 && row.inactived_at === null && row.is_customer).sort((a,b) => compare()(a.cod, b.cod))
    }
}
  
export default connect(mapStateToProps, mapDispatchProps)(Form.create()(CerticatesForm));