import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Table, Tag, Icon, Dropdown, Menu, Modal, Button, Input, Badge } from 'antd';
import Moment from 'react-moment';
import { compare } from 'natural-orderby';

import Archive from './Includes/Archive';
import { ModuleHeader } from '../ModuleHeader';
import { HeaderContainer, HeaderLeftContent } from './styles';
import { getDefaultPaginationConfig } from '~/utils/get-default-pagination-config';
import { STORAGE } from '~/constants/storage.constants';

const { Column } = Table;
const Search = Input.Search;

const getColorTag = (status) => {
    switch(status){
        case 'Expirado': return '#f5222d';
        case 'Expirando': return '#fadb14';
        case 'Ativo': return '#52c41a';
        case 'Arquivado': return '#8c8c8c';
        default: console.log(`Status: ${status} not supported`);
    } 
}

const getBackground = (type) => {
  // eslint-disable-next-line default-case
  switch(type){
    case 'OAB': return 'row-blue';
    case 'CFM': return 'row-blue';
    case 'Procuração PF': return 'row-orange';
    case 'Procuração PJ': return 'row-orange';
    case 'CREMESP': return 'row-green';
  }
}

class ProcurationsList extends Component {

    state = {
      viewArchived: false,
      view: null,
      search: '',
      archive: null
    }

  onAction = (e, procuration) => {
    // eslint-disable-next-line default-case
    switch(e.key){
      case 'edit': 
      this.props.showEdit(procuration);
      break;
      case 'archive': 
      this.setState({archive: procuration});
      break;
      case 'unArchive': 
      Modal.confirm({
        title: 'Você realmente deseja desarquivar este lembrete?',
        content: 'Você poderá arquivar este lembrete novamente no futuro',
        okText: 'Sim',
        okType: 'danger',
        cancelText: 'Cancelar',
        onOk: () => this.props.unArchive(procuration.id)
      });
      break;
      case 'delete': 
        Modal.confirm({
          title: 'Você realmente deseja excluir este lembrete?',
          content: 'Esta ação não poderá ser desfeita.',
          okText: 'Sim',
          okType: 'danger',
          cancelText: 'Cancelar',
          onOk: () => this.props.deleteProcuration(procuration.id)
        });
      break;
    }
  }

  render() {
    const { viewArchived } = this.state;
    const { search } = this.state;
    const { findUser, isLoading } = this.props;

    const procurations = this.props.procurations && this.props.customers ? this.props.procurations.map(item => {
      return ({
        ...item,
        name: (!!item.customer_name && item.nature === 'PJ') ? item.customer_name : item.owner_name,
        })
      }):[];

    let filteredProcurations = (procurations !== null)? procurations.filter((str) => {

      if(str.name.toLowerCase().indexOf(search.toLowerCase()) >= 0){
        return true;
      }

      return false;
    }): [];

    const archived = filteredProcurations.filter(r=>r.status === 'Arquivado');
    const unArchived = filteredProcurations.filter(r=>r.status !== 'Arquivado');

    return (  
      <div>
        {this.state.archive !== null && (
        <Archive
          data={this.state.archive}
          onClose={() => this.setState({archive: null})}
          saveProcuration={this.props.saveProcuration}
        />
        )}
        <div style={{ 
            margin: `0px -16px`, 
            padding: '15px', 
            background: '#fff', 
            marginBottom: `10px` }}>
              <ModuleHeader 
                breadcrumbs={['Dep. Societário', 'Lembretes']} 
                title="Lembretes"
                search={
                  <>
                    <Search 
                      placeholder="Pesquisar lembretes" 
                      onChange={e => this.setState({search: e.target.value})}
                      style={{ maxWidth: 300 }}
                    />
                    <Badge count={archived.length} overflowCount={999}>
                      <Button 
                        type={viewArchived?'primary':'default'}
                        onClick={() => this.setState({viewArchived: !viewArchived})} >
                        <Icon type="inbox" /> Arquivados
                      </Button>
                    </Badge>
                  </>
                }
                actions={
                  <Button type="primary" onClick={() => this.props.showModal()}>
                    <Icon type="plus" /> Lembrete
                  </Button>
                }
              />
        </div>
        <div className="table-clabs">
          <Table 
            rowKey="id"
            loading={isLoading}
            dataSource={(viewArchived?archived:unArchived)} 
            pagination={getDefaultPaginationConfig({
              storageKey: STORAGE.defaultPageSize.procurations,
              defaultPageSize: 1000
            })}
            bordered={true}
            rowClassName={(record) => `${getBackground(record.type)}`}
            scroll={{x: true }}
          >
            <Column
                title="Status"
                dataIndex="status"
                key="status"
                fixed="left"
                width={150}
                filters={[{
                    text: 'Ativo',
                    value: 'Ativo',
                  }, {
                    text: 'Expirando',
                    value: 'Expirando',
                  },
                  {
                    text: 'Expirado',
                    value: 'Expirado',
                  }
                ]}
                filterMultiple={false}
                onFilter={(value, record) => record.status.indexOf(value) >= 0}
                render={status => <center><Tag color={getColorTag(status)} key={status}>{status}</Tag></center>}
              />
              <Column
                  title="Agendado para"
                  align="center"
                  dataIndex="scheduled_at"
                  key="scheduled_at"
                  fixed="left"
                  render={scheduled_at => scheduled_at && <Moment format="DD/MM/YYYY">{scheduled_at}</Moment>}
                  width={150}
              />
              <Column
                  title="Nome"
                  dataIndex="name"
                  key="name"
                  fixed="left"
                  width={window.innerWidth > 1920 ? 600 : 400}
                  sorter={(a, b) => a.name && a.name.localeCompare(b.name)}
              />
              <Column
                  title="Tipo de lembrete"
                  dataIndex="type"
                  key="type"
                  filters={[
                    {
                      text: 'OAB',
                      value: 'OAB',
                    },
                    {
                      text: 'CFM',
                      value: 'CFM',
                    },
                    {
                      text: 'Procuração PF',
                      value: 'Procuração PF',
                    },
                    {
                      text: 'Procuração PJ',
                      value: 'Procuração PJ',
                    },
                    {
                      text: 'CREMESP',
                      value: 'CREMESP',
                    },
                    {
                      text: 'Anuidade CRM',
                      value: 'Anuidade CRM',
                    },
                    {
                      text: 'Anuidade CREA',
                      value: 'Anuidade CREA',
                    },
                    {
                      text: 'Anuidade OAB',
                      value: 'Anuidade OAB',
                    },
                    {
                      text: 'Anuidade CRO',
                      value: 'Anuidade CRO',
                    },
                    {
                      text: 'TFE',
                      value: 'TFE',
                    },
                    {
                      text: 'TFA',
                      value: 'TFA',
                    },
                    {
                      text: 'TRSS',
                      value: 'TRSS',
                    },
                  ]}
                filterMultiple={false}
                onFilter={(value, record) => record.type.indexOf(value) >= 0}
              />
              {/* <Column
                  title="Telefone"
                  dataIndex="phone_formated"
                  key="phone_formated"
                  width={150}
              /> */}
              <Column
                  title="Emissão"
                  dataIndex="emission_date"
                  key="emission_date"
                  sorter={(a, b) => a.emission_date && a.emission_date.localeCompare(b.emission_date)}
                  render={emission_date => <Moment format="DD/MM/YYYY">{emission_date}</Moment>}
                  width={150}
              />
              {/* <Column
                  title="Valor"
                  dataIndex="amount"
                  key="amount"
                  render={amount => new Intl.NumberFormat('pt-br', {
                    style: 'currency',
                    currency: 'BRL'
                  }).format(amount)}
                  width={150}
              /> */}
              <Column
                  title="Expiração"
                  dataIndex="due_date"
                  key="due_date"
                  render={due_date => <Moment format="DD/MM/YYYY">{due_date}</Moment>}
                  sorter={(a, b) => a.due_date && a.due_date.localeCompare(b.due_date)}
                  width={150}
              />
              <Column
                  title="Dias"
                  dataIndex="expires_in"
                  key="expires_in"
                  render={expires_in => <center>{expires_in<0?0:expires_in}</center>}
                  width={100}
                  sorter={(a, b) => a.expires_in && (a.expires_in-b.expires_in)}
                  sortDirections={['descend', 'ascend']}
                  defaultSortOrder={'ascend'}
              />
              <Column
                  title="Natureza"
                  dataIndex="nature"
                  key="nature"
                  width={120}
                  filters={[{
                    text: 'Pessoa Jurídica',
                    value: 'PJ',
                  }, {
                    text: 'Pessoa Física',
                    value: 'PF',
                  }
                ]}
                filterMultiple={false}
                onFilter={(value, record) => record.nature.indexOf(value) >= 0}
              />
              {/* <Column
                  title="Senha"
                  dataIndex="password"
                  key="password"
                  width={180}
                  render={(text, record) => {
                      if(record.password =='' || record.password === null) return '';

                      if(record.id !== view){
                          return(
                              <center><Button type="dashed" size="small" onClick={() => this.setState({view: record.id})}> visualizar <Icon type="eye" /></Button></center>
                          )
                      }else{
                          return(
                              <center><Button type="dashed" size="small" onClick={() => this.setState({view: null})}> {record.password} <Icon type="eye" /></Button></center>
                          )
                      }
                  }}
              /> */}
              {(viewArchived === true) && (
              <Column
                  title="Arquivado Em"
                  dataIndex="archied_at"
                  key="archied_at"
                  width={150}
                  render={key => <Moment format="DD/MM/YYYY">{key}</Moment>}
                  sorter={(a, b) => a.archied_at && a.archied_at.localeCompare(b.archied_at)}
              />)}
              {(viewArchived === true) && (
              <Column
                  title="Arquivado Por"
                  dataIndex="archived_by"
                  key="archived_by"
                  width={180}
                  render={archived_by => findUser(archived_by).name}
              />)}
              {(viewArchived === true) && (
              <Column
                  title="Motivo"
                  dataIndex="archived_message"
                  key="archived_message"
                  width={300}
              />
              )}
              <Column
                  dataIndex="actions"
                  key="actions"
                  fixed="right"
                  width={100}
                  render={(text, record, index) => (
                  <center key={index}>
                      <Dropdown overlay={
                          <Menu onClick={(e) => this.onAction(e, record)}>
                          <Menu.Item key="edit">
                              <Icon type="edit"></Icon> Editar
                          </Menu.Item>
                          <Menu.Divider />
                          {record.status !=='Arquivado'?
                          <Menu.Item key="archive"><Icon type="inbox"></Icon> Arquivar</Menu.Item>
                          :
                          <Menu.Item key="unArchive"><Icon type="inbox"></Icon> Desarquivar</Menu.Item>}
                          <Menu.Item key="delete"><Icon type="delete"></Icon> Excluir</Menu.Item>
                          </Menu>
                      } trigger={['click']}>
                          <a className="ant-dropdown-link" href="#">
                          Ações <Icon type="down" />
                          </a>
                      </Dropdown>
                  </center>
              )}
              />
          </Table>
        </div>
      </div>
      )
  }
}

const mapStateToProps = state => {
  return{
    procurations: state.procurations.list,
    isLoading: state.procurations.isLoading,
    customers: state.customers.list.filter(row => row.enabled && row.inactived_at === null && row.is_customer).sort((a,b) => compare()(a.cod, b.cod)),
    findUser: (id) => state.users.filter(r=>r.id===id)[0],
  }
}

const mapDispatchProps = dispatch => {
  return{
    deleteProcuration: (id) => dispatch({
      type: 'DELETE_PROCURATION',
      payload: {
        request:{
          method: 'delete',
          url:'/procurations/'+id,
        }
      },
      id: id
    }),
    unArchive: (id) => dispatch({
      type: 'SAVE_PROCURATION',
      payload: {
        request:{
          method: 'put',
          url:'/procurations/'+id+'/unarchive',
        }
      },
      id: id
    }),
    saveProcuration: (data) => dispatch({
      type: 'SAVE_PROCURATION_SUCCESS',
      payload: data
    })
  }
}

export default connect(mapStateToProps, mapDispatchProps)(ProcurationsList);