import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Table,
  Tag,
  Icon,
  Dropdown,
  Menu,
  Modal,
  Button,
  Input,
  Badge,
  Radio
} from 'antd';
import Moment from 'react-moment';
import { compare } from 'natural-orderby';

import Archive from './Includes/Archive';
import Download from '../Components/Download';
import moment from 'moment';
import { ModuleHeader } from '../ModuleHeader';
import {
  HeaderContainer,
  HeaderLeftContent,
  PendentBadge,
  RegisteredBadge
} from './styles';
import { getDefaultPaginationConfig } from '~/utils/get-default-pagination-config';
import { STORAGE } from '~/constants/storage.constants';

import {
  Cod
} from '~/components/UI';

const { Column } = Table;
const Search = Input.Search;

const getColorTag = (state) => {
    switch(state){
        case 'Expirado': return '#f5222d';
        case 'Expirando': return '#fadb14';
        case 'Ativo': return '#52c41a';
        case 'Arquivado': return '#8c8c8c';
        default: console.log(`Status: ${state} not supported`);
    } 
}

const getBackground = (type) => {
  switch(type){
    case 'E-CPF A1': return 'row-gray';
    case 'E-CPF A3': return 'row-gray';
    case 'E-CNPJ A1': return 'row-blue';
    case 'E-CNPJ A3': return 'row-blue';
  }
}

class CertificatesList extends Component {

    state = {
      viewArchived: false,
      visualizationMode: 'pendent',
      view: null,
      search: '',
      archive: null
    }

  onAction = (e, certificate) => {
    switch(e.key){
      case 'edit': 
      this.props.showEdit(certificate);
      break;
      case 'archive': 
      this.setState({archive: certificate});
      break;
      case 'unArchive': 
      Modal.confirm({
        title: 'Você realmente deseja desarquivar este certificado?',
        content: 'Você poderá arquivar este certificado novamente no futuro',
        okText: 'Sim',
        okType: 'danger',
        cancelText: 'Cancelar',
        onOk: () => this.props.unArchive(certificate.id)
      });
      break;
      case 'delete': 
        Modal.confirm({
          title: 'Você realmente deseja excluir este certificado?',
          content: 'Esta ação não poderá ser desfeita.',
          okText: 'Sim',
          okType: 'danger',
          cancelText: 'Cancelar',
          onOk: () => this.props.deleteCertificate(certificate.id)
        });
      break;
    }
  }

  getFilename = (certificate) => {  
    return `${certificate.customer_name}_CERTIFICADO_${moment().format('DD-MM-YYYY')}.pdf`;
  } 

  render() {
    const { visualizationMode } = this.state;
    const { search } = this.state;
    const { findUser, isLoading } = this.props;

    const formattedCertificates = this.props.certificates && this.props.customers ? this.props.certificates.map(item => {
      return ({
        ...item,
        name: (!!item.customer_name && item.nature === 'PJ') ? item.customer_name : item.owner_name,
        })
      }):[];

    let filteredCertificates = (formattedCertificates !== null)? formattedCertificates.filter((str) => {

      if(str.name.toLowerCase().indexOf(search.toLowerCase()) >= 0){
        return true;
      }

      if (str.customer_cod && str.customer_cod.includes(search)) {
        return true;
      }

      return false;
    }): [];

    const certificates = {
      archived: filteredCertificates.filter(({ state }) => state === 'Arquivado'),
      pendent: filteredCertificates.filter(({ status, state }) => (status === 'pendent') && state !== 'Arquivado'),
      registered: filteredCertificates.filter(({ status, state }) => status === 'registered' && state !== 'Arquivado')
    };
    return (  
      <div>
        {this.state.archive !== null && (
        <Archive
          data={this.state.archive}
          onClose={() => this.setState({archive: null})}
          saveCertificate={this.props.saveCertificate}
        />
        )}
        <div style={{ 
            margin: `0px -16px`, 
            padding: '15px', 
            background: '#fff', 
            marginBottom: `10px` }}>
              <ModuleHeader 
                breadcrumbs={['Dep. Societário', 'Certificados digitais']} 
                title="Certificados Digitais"
                search={
                  <Search 
                    placeholder="Pesquisar certificados" 
                    onChange={e => this.setState({search: e.target.value})}
                    style={{ maxWidth: 300 }}
                  />
                }
                actions={
                  <>
                    <Radio.Group
                      value={visualizationMode}
                      onChange={({ target }) => this.setState({ visualizationMode: target.value })}
                    >
                      <Radio.Button value="pendent">
                        <PendentBadge count={certificates.pendent.length}>
                          Pendentes
                        </PendentBadge>
                      </Radio.Button>
                      <Radio.Button value="registered">
                        <RegisteredBadge count={certificates.registered.length}>
                          Cadastrados
                        </RegisteredBadge>
                      </Radio.Button>
                    </Radio.Group>
                    <Badge count={certificates.archived.length} overflowCount={999} showZero>
                      <Button
                        icon="inbox"
                        type={visualizationMode === 'archived' ? 'primary' : 'default'}
                        onClick={() => this.setState({ visualizationMode: 'archived' })}
                      >
                        Arquivados
                      </Button>
                    </Badge>
                    <Button type="primary" onClick={() => this.props.showModal()}>
                        <Icon type="plus" /> Certificado
                    </Button>
                  </>
                }
              />
        </div>
        <div className="table-clabs">
          <Table 
            rowKey="id"
            loading={isLoading}
            dataSource={certificates[visualizationMode]} 
            pagination={getDefaultPaginationConfig({
              storageKey: STORAGE.defaultPageSize.certificates,
              defaultPageSize: 1000
            })}
            bordered={true}
            rowClassName={(record) => `${getBackground(record.type)}`}
            scroll={{ x: visualizationMode === 'archived' ? 2000 : 1800 }}
          >
            <Column
                title="Status"
                dataIndex="state"
                key="state"
                fixed="left"
                width={150}
                filters={[{
                    text: 'Ativo',
                    value: 'Ativo',
                  }, {
                    text: 'Expirando',
                    value: 'Expirando',
                  },
                  {
                    text: 'Expirado',
                    value: 'Expirado',
                  }
                ]}
                filterMultiple={false}
                onFilter={(value, record) => record.state.indexOf(value) >= 0}
                render={(state, record) => {
                  if (record.status === 'pendent') {
                    return <center><Tag color="#fa8c16">Pendente</Tag></center>
                  }

                  return <center><Tag color={getColorTag(state)} key={state}>{state}</Tag></center>;
                }}
              />
              <Column
                title="Agendado para"
                align="center"
                dataIndex="scheduled_at"
                key="scheduled_at"
                fixed="left"
                render={scheduled_at => scheduled_at && <Moment format="DD/MM/YYYY">{scheduled_at}</Moment>}
                width={150}
              />
              <Column
                title="Cód"
                dataIndex="customer_cod"
                key="customer_cod"
                fixed="left"
                width={100}
                sorter={(a, b) => a.customer_cod && a.customer_cod.localeCompare(b.customer_cod)}
              />
              <Column
                title="BIRD"
                dataIndex="bird"
                key="bird"
                width={100}
                fixed="left"
                render={(value) => value ? 'Sim': 'Não'}
              />
              <Column
                title="Nome"
                dataIndex="name"
                key="name"
                fixed="left"
                width={450}
                sorter={(a, b) => a.name && a.name.localeCompare(b.name)}
                render={(name) => name.length > 35 ? name.substring(0, 35) + '...' : name}
              />
              <Column
                title="Societário"
                dataIndex="dash_task_id"
                key="dash_task_id"
                width={150}
                render={(value) => value ? <Cod>#SOC{value}</Cod>: 'Não atrelado'}
              />
               <Column
                ellipsis
                title="Bloco de notas"
                dataIndex="notes"
                key="notes"
                width={150}
              />
              <Column
                ellipsis
                title="Tipo de certificado"
                dataIndex="type"
                key="type"
                filters={[{
                    text: 'E-CPF A1',
                    value: 'E-CPF A1',
                  }, {
                    text: 'E-CPF A3',
                    value: 'E-CPF A3',
                  },
                  {
                    text: 'E-CNPJ A1',
                    value: 'E-CNPJ A1',
                  },
                  {
                    text: 'E-CNPJ A3',
                    value: 'E-CNPJ A3',
                  },
                ]}
                filterMultiple={false}
                onFilter={(value, record) => record.type.indexOf(value) >= 0}
                width={150}
              />
              <Column
                title="Emissão"
                dataIndex="emission_date"
                key="emission_date"
                sorter={(a, b) => a.emission_date && a.emission_date.localeCompare(b.emission_date)}
                render={emission_date => emission_date ? <Moment format="DD/MM/YYYY">{emission_date}</Moment> : null}
                width={150}
              />
              <Column
                title="Expiração"
                dataIndex="due_date"
                key="due_date"
                render={due_date => due_date ? <Moment format="DD/MM/YYYY">{due_date}</Moment> : null}
                sorter={(a, b) => a.due_date && a.due_date.localeCompare(b.due_date)}
                width={150}
              />
              <Column
                title="Dias"
                dataIndex="expires_in"
                key="expires_in"
                render={expires_in => <center>{expires_in<0?0:expires_in}</center>}
                width={100}
                sorter={(a, b) => a.expires_in - b.expires_in}
                sortDirections={['descend', 'ascend']}
                defaultSortOrder={'ascend'}
              />
              <Column
                title="Natureza"
                dataIndex="nature"
                key="nature"
                width={120}
                filters={[{
                    text: 'Pessoa Jurídica',
                    value: 'PJ',
                  }, {
                    text: 'Pessoa Física',
                    value: 'PF',
                  }
                ]}
                filterMultiple={false}
                onFilter={(value, record) => record.nature.indexOf(value) >= 0}
              />
              {/* <Column
                  title="Senha"
                  dataIndex="password"
                  key="password"
                  width={180}
                  render={(text, record) => {
                      if(record.password =='' || record.password === null) return '';

                      if(record.id !== view){
                          return(
                              <center><Button type="dashed" size="small" onClick={() => this.setState({view: record.id})}> visualizar <Icon type="eye" /></Button></center>
                          )
                      }else{
                          return(
                              <center><Button type="dashed" size="small" onClick={() => this.setState({view: null})}> {record.password} <Icon type="eye" /></Button></center>
                          )
                      }
                  }}
              /> */}
              {(visualizationMode === 'archived') && (
              <Column
                  title="Arquivado Em"
                  dataIndex="archied_at"
                  key="archied_at"
                  width={150}
                  render={key => <Moment format="DD/MM/YYYY">{key}</Moment>}
                  sorter={(a, b) => a.archied_at && a.archied_at.localeCompare(b.archied_at)}
              />)}
              {(visualizationMode === 'archived') && (
              <Column
                  title="Arquivado Por"
                  dataIndex="archived_by"
                  key="archived_by"
                  width={180}
                  render={archived_by => findUser(archived_by).name}
              />)}
              {(visualizationMode === 'archived') && (
              <Column
                  title="Motivo"
                  dataIndex="archived_message"
                  key="archived_message"
                  width={300}
              />
              )}
              <Column
                  dataIndex="actions"
                  key="actions"
                  fixed="right"
                  width={100}
                  render={(text, record, index) => (
                  <center key={index}>
                      <Dropdown overlay={
                          <Menu onClick={(e) => this.onAction(e, record)}>
                          <Menu.Item key="edit">
                              <Icon type="edit"></Icon> Editar
                          </Menu.Item>
                          <Menu.Item key="print">
                            <Download url={`/certificates/${record.id}/print`} filename={this.getFilename(record)}>
                              <div>
                                <Icon type="printer"></Icon> Gerar PDF
                              </div>
                            </Download>
                          </Menu.Item>
                          <Menu.Divider />
                          {record.state !=='Arquivado'?
                          <Menu.Item key="archive"><Icon type="inbox"></Icon> Arquivar</Menu.Item>
                          :
                          <Menu.Item key="unArchive"><Icon type="inbox"></Icon> Desarquivar</Menu.Item>}
                          <Menu.Item key="delete"><Icon type="delete"></Icon> Excluir</Menu.Item>
                          </Menu>
                      } trigger={['click']}>
                          <a className="ant-dropdown-link" href="#">
                          Ações <Icon type="down" />
                          </a>
                      </Dropdown>
                  </center>
              )}
              />
          </Table>
        </div>
      </div>
      )
  }
}

const mapStateToProps = state => {
  return{
    certificates: state.certificates.list,
    isLoading: state.certificates.isLoading,
    customers: state.customers.list.filter(row => row.enabled === 1 && row.inactived_at === null && row.is_customer).sort((a,b) => compare()(a.cod, b.cod)),
    findUser: (id) => state.users.filter(r=>r.id===id)[0],
  }
}

const mapDispatchProps = dispatch => {
  return{
    deleteCertificate: (id) => dispatch({
      type: 'DELETE_CERTIFICATE',
      payload: {
        request:{
          method: 'delete',
          url:'/certificates/'+id,
        }
      },
      id: id
    }),
    unArchive: (id) => dispatch({
      type: 'SAVE_CERTIFICATE',
      payload: {
        request:{
          method: 'put',
          url:'/certificates/'+id+'/unarchive',
        }
      },
      id: id
    }),
    saveCertificate: (data) => dispatch({
      type: 'SAVE_CERTIFICATE_SUCCESS',
      payload: data
    })
  }
}

export default connect(mapStateToProps, mapDispatchProps)(CertificatesList);