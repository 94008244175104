import {
  Button,
  Col,
  Divider,
  Drawer,
  Form,
  Modal,
  Progress,
  Row,
  Select,
  DatePicker as AntdDatePicker,
  Switch
} from "antd";
import React, { Component } from "react";
import { connect } from "react-redux";
import randomUid from "uid";
import { compare } from 'natural-orderby';

import * as checklistActions from "~/containers/Checklist/actions";
import * as drawerChecklistActions from "~/containers/DrawerChecklist/actions";

import { DatePicker } from "~/components/UI/DatePicker/Locale";
import moment from "moment";
import TasksModal from "./Modals/TasksModal";
import TasksTable from "./Tables/TasksTable";
import { PERMISSIONS } from '~/constants/permissions.constants';
import Tip from '~/components/Tip';

const { Option } = Select;
const { OptGroup } = Select;
const { MonthPicker } = AntdDatePicker

const DEFAULT_MODAL_DATA = {
  uid: undefined,
  department: undefined,
  task: undefined,
  done: undefined,
  index: undefined,
};

class DrawerChecklist extends Component {
  state = {
    isModalOpen: false,
    tasks: [],
    deletedTasks: [],
    showTasksRequiredError: false,
    defaultModalData: DEFAULT_MODAL_DATA,
    prevStatus: null,
    defaultFilteredDepartments: [],
    is_revised: false,
  };

  componentDidUpdate = (prevProps) => {
    const { drawer } = this.props;

    if (drawer.show !== prevProps.drawer.show) {
      if (drawer.show && drawer.data.id) {
        this.setState({
          prevStatus: drawer.data.status,
          tasks: drawer.data.tasks,
          defaultFilteredDepartments: this.getDefaultFilteredValue(),
          is_revised: drawer.data.is_revised,
         })
      }
    }
  };

  closeDrawer = () => {
    this.setState({ tasks: [] });
    this.props.closeDrawer();
  };

  closeModal = () =>
    this.setState({ isModalOpen: false, defaultModalData: DEFAULT_MODAL_DATA });

  openModal = () => this.setState({ isModalOpen: true });

  showTasksRequiredError = () =>
    this.setState({ showTasksRequiredError: true });

  hideTasksRequiredError = () =>
    this.setState({ showTasksRequiredError: false });

  handleSaveTask = () => {
    const { form } = this.props;

    form.validateFields(["task", "department"], (error, values) => {
      if (error) return;

      const { task, department } = values;
      const {
        tasks,
        defaultModalData: { uid, done, index },
      } = this.state;

      if (uid) {
        this.setState({
          tasks: [
            ...tasks.map((item) =>
              item.uid !== uid
                ? item
                : { uid, name: task, department, done, index }
            ),
          ],
        });
      } else {
        this.setState({
          tasks: [
            ...tasks,
            {
              uid: randomUid(10),
              name: task,
              department,
              done: 0,
              index: tasks.length + 1,
            },
          ],
        });
      }

      this.closeModal();
      this.hideTasksRequiredError();
    });
  };

  handleRemoveTask = (uid) =>
    Modal.confirm({
      title: "Você realmente deseja excluir esta tarefa?",
      content: `Esta ação não poderá ser desfeita.`,
      okText: "Sim",
      okType: "danger",
      cancelText: "Cancelar",
      onOk: () => {
        const { tasks, deletedTasks } = this.state;

        const deletedTask = tasks.find((task) => task.uid === uid);

        this.setState({
          tasks: this.state.tasks.filter((task) => task.uid !== uid),
          deletedTasks: [...deletedTasks, deletedTask],
        });
      },
  });

  handleEditTask = (uid) => {
    const currentTask = this.state.tasks.find((task) => task.uid === uid);

    this.setState({
      defaultModalData: {
        uid: currentTask.uid,
        department: currentTask.department,
        task: currentTask.name,
        done: currentTask.done,
        index: currentTask.index,
      },
    });
    this.openModal();
  };

  handleCheckTask = (uid) => {
    const updatedTasks = this.state.tasks.map((task) => {
      if (task.uid === uid) {
        return {
          ...task,
          done: task.done === 1 ? 0 : 1,
        };
      }
      return task;
    });

    this.setState({ tasks: updatedTasks });
  };

  handleConfirmChangeStatus = (status) => {
    const { setFields } = this.props.form;
    const { prevStatus } = this.state;

    setFields({
      status: {
        value: prevStatus
      }
    });

    Modal.confirm({
      title: 'Você realmente deseja alterar o status?',
      content: 'As alterações nas tarefas que foram feitas antes de salvar serão perdidas.',
      okText: 'Sim',
      okType: 'danger',
      cancelText: 'Cancelar',
      onOk: () => {
        if (prevStatus !== status) {
          this.setState({ prevStatus: status });
        }

        this.handleChangeStatus(status);
      }
    })
  }

  handleChangeStatus = (status) => {
    const { tasks } = this.state;
    const { setFields } = this.props.form;
    const { checklistTasks } = this.props;

    setFields({
      status: {
        value: status
      }
    });

    if (status === 1) {
      const closingOutputTasks = checklistTasks.filter(({ status }) => status === 1);
      const formattedClosingOutputTasks = closingOutputTasks.map(
        (task, index) => ({
          uid: randomUid(10),
          ...task,
          done: 0,
          index,
        })
      );

      this.setState({ tasks: formattedClosingOutputTasks });
      return this.hideTasksRequiredError();
    }

    if (status === 2) {
      const inputTasks = checklistTasks.filter(({ status }) => status === 2);
      const formattedInputTasks = inputTasks.map((task, index) => ({
        uid: randomUid(10),
        ...task,
        done: 0,
        index,
      }));

      this.setState({ tasks: formattedInputTasks });
      return this.hideTasksRequiredError();
    }

    if (status === 4) {
      const inputTasks = checklistTasks.filter(({ status }) => status === 4);
      const formattedInputTasks = inputTasks.map((task, index) => ({
        uid: randomUid(10),
        ...task,
        done: 0,
        index,
      }));

      this.setState({ tasks: formattedInputTasks });
      return this.hideTasksRequiredError();
    }

    if (status === 3) {
      const changingOutputTasks = checklistTasks.filter(({ status }) => status === 3);
      const formattedChangingOutputTasks = changingOutputTasks.map((task, index) => ({
        uid: randomUid(10),
        ...task,
        done: 0,
        index
      }));

      this.setState({ tasks: formattedChangingOutputTasks });
      return this.hideTasksRequiredError();
    }

    return this.setState({ tasks: [], deletedTasks: tasks });
  };

  handleSubmit = () => {
    const { form } = this.props;
    const { drawer } = this.props;
    const { tasks } = this.state;

    form.validateFields(
      [
        "customer_id",
        "status",
        "requested_at",
        "competency_at",
        "due_at"
      ],
      (error, values) => {
        if (tasks.length === 0) return this.showTasksRequiredError();

        if (error) return;

        this.hideTasksRequiredError();

        const isUpdating = !!drawer.data.id;

        try {
          const data = {
            ...values,
            requested_at: moment(values.requested_at).format("YYYY-MM-DD"),
            competency_at: moment(values.competency_at)
              .set({ date: 1 })
              .format("YYYY-MM-DD"),
            due_at: moment(values.due_at)
              .set({ date: 1 })
              .format("YYYY-MM-DD"),
            tasks,
            is_revised: this.state.is_revised
          };

          if (isUpdating) {
            data.deleted_tasks = this.state.deletedTasks;
            const checklistId = drawer.data.id;

            this.props.updateChecklist(data, checklistId);
          } else {
            this.props.createChecklist(data);
          }
        } catch (error) {
          console.log(error);
        } finally {
          this.closeDrawer();
        }
      }
    );
  };

  handleState = (state) => {
    this.setState(state);
  };

  getDefaultFilteredValue = () => {
    const { user, permissions } = this.props;

    const departmentsOfPermissions = {
      [PERMISSIONS.checklist.columns.legalization]: 1,
      [PERMISSIONS.checklist.columns.administrative]: 2,
      [PERMISSIONS.checklist.columns.accounting]: 3,
      [PERMISSIONS.checklist.columns.fiscal]: 4,
      [PERMISSIONS.checklist.columns.personal]: 5,
      [PERMISSIONS.checklist.columns.cs]: 6,
    }

    const permissionGroup = permissions.find((item) => item.id === user.permissions_group_id);

    const departments = [];

    if (!permissionGroup) {
      if (user.permissions) {
        user.permissions.forEach((item) => {
          if (typeof departmentsOfPermissions[item] !== 'undefined') {
            departments.push(departmentsOfPermissions[item]);
          }
        })
        return departments;
      } else {
        return [];
      }
    };

    permissionGroup.permissions.forEach((item) => {
      if (departmentsOfPermissions[item]) {
        departments.push(departmentsOfPermissions[item])
      }
    });

    return departments;
  }

  getConcludedTasksPercentage = () => {
    const { defaultFilteredDepartments } = this.state;
    const { tasks } = this.state;

    if (defaultFilteredDepartments.length > 0) {
      const tasksBasedInPermissions = tasks.filter((task) => defaultFilteredDepartments.includes(task.department));

      const concludedTasksCount = tasksBasedInPermissions
        .filter((task) => task.done).length;

      return Math.ceil((100 * concludedTasksCount) / tasksBasedInPermissions.length);
    } else {
      const concludedTasksCount = tasks.filter((task) => task.done).length;
      return Math.ceil((100 * concludedTasksCount) / tasks.length);
    }
  }

  render() {
    const { drawer, customers } = this.props;
    const { getFieldDecorator, getFieldValue } = this.props.form;
    const { defaultFilteredDepartments, tasks } = this.state;

    if (!customers) return null;

    const data = drawer.data;

    const activeCustomers = customers.filter((customer) => customer.enabled);
    const pendingCustomers = customers.filter((customer) => !customer.enabled);

    return (
      <Drawer
        maskClosable={false}
        destroyOnClose
        visible={drawer.show}
        title={data.id ? "Editar item" : "Cadastrar novo item"}
        width={800}
        onClose={this.closeDrawer}
      >
        <Form layout="vertical">
          <Form.Item label="Cliente">
            {getFieldDecorator("customer_id", {
              initialValue: data.customer_id,
              rules: [
                {
                  required: true,
                  message: "Por favor, informe o nome do cliente",
                },
              ],
            })(
              <Select
                placeholder="Selecione um cliente"
                showSearch
                optionFilterProp="children"
                filterOption={true}
              >
                <OptGroup label="Ativos">
                  {activeCustomers.map((customer) => (
                    <Option
                      key={customer.id}
                      value={customer.id}
                    >{`${customer.cod} - ${customer.name}`}</Option>
                  ))}
                </OptGroup>
                <OptGroup label={<span>Pendentes <Tip>São clientes que ainda estão em processo no Dep. Societário.</Tip></span>}>
                  {pendingCustomers.map((customer) => (
                    <Option
                      key={customer.id}
                      value={customer.id}
                    >{`${customer.cod} - ${customer.name}`} <Tip>Este cliente ainda está em processo no Dep. Societário.</Tip></Option>
                  ))}
                </OptGroup>
              </Select>
            )}
          </Form.Item>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item label="Status">
                {getFieldDecorator("status", {
                  initialValue: data.status,
                  rules: [
                    {
                      required: true,
                      message: "Por favor, informe o status",
                    },
                  ],
                })(
                  <Select
                    placeholder="Selecione um status"
                    onSelect={this.props.drawer.data.id
                      ? this.handleConfirmChangeStatus
                      : this.handleChangeStatus
                    }
                  >
                    <Option value={2}>Entrada</Option>
                    <Option value={4}>Entrada (Troca de Contabilidade)</Option>
                    <Option value={1}>Saída (Encerramento)</Option>
                    <Option value={3}>Saída (Troca de Contabilidade)</Option>
                  </Select>
                )}
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Data da solicitação">
                {getFieldDecorator("requested_at", {
                  initialValue: data.requested_at
                    ? moment(data.requested_at)
                    : undefined,
                  rules: [
                    {
                      required: true,
                      message: "Por favor, informe a data de solicitação",
                    },
                  ],
                })(<DatePicker />)}
              </Form.Item>
            </Col>
          </Row>
          <Divider>Responsável {[1, 3].includes(getFieldValue('status')) ? 'até' : 'a partir de'}</Divider>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item label="Competência">
                {getFieldDecorator("competency_at", {
                  initialValue: data.competency_at
                    ? moment(data.competency_at)
                    : undefined,
                })(
                  <MonthPicker
                    placeholder="Selecione uma data"
                    format={'MM/YYYY'}
                    style={{ width: '100%' }}
                  />
                )}
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Vencimento">
                {getFieldDecorator("due_at", {
                  initialValue: data.due_at
                    ? moment(data.due_at)
                    : undefined,
                })(
                  <MonthPicker
                    placeholder="Selecione uma data"
                    format={'MM/YYYY'}
                    style={{ width: '100%' }}
                  />
                )}
              </Form.Item>
            </Col>
          </Row>
          <Divider>Tarefas</Divider>
          {tasks.length > 0 && (
            <>
              <Progress percent={this.getConcludedTasksPercentage()} />
              <TasksTable
                tasks={this.state.tasks}
                onCheckTask={this.handleCheckTask}
                onEditTask={this.handleEditTask}
                onRemoveTask={this.handleRemoveTask}
                onSetState={this.handleState}
                state={this.state}
                defaultFilteredDepartments={defaultFilteredDepartments}
              />
            </>
          )}
          <Button
            {...(this.state.showTasksRequiredError && {
              style: {
                borderColor: "#f5222d",
                color: "#f5222d",
              },
            })}
            onClick={this.openModal}
          >
            Adicionar tarefa
          </Button>
          {this.state.showTasksRequiredError && (
            <div
              style={{
                color: "#f5222d",
                marginTop: 2,
              }}
            >
              Por favor, adicione pelo menos uma tarefa
            </div>
          )}
          { data.id && (
            <Form.Item label="Revisado" style={{ marginTop: "20px" }}>
              <Switch 
                defaultChecked={this.state.is_revised}
                onChange={(checked) => this.setState({ is_revised: checked })}
              />
            </Form.Item>
          )}
        </Form>
        <TasksModal
          isOpen={this.state.isModalOpen}
          onClose={this.closeModal}
          onSaveTask={this.handleSaveTask}
          data={this.state.defaultModalData}
          getFieldDecorator={getFieldDecorator}
        />
        <div
          style={{
            borderTop: "1px solid #e8e8e8",
            textAlign: "right",
            background: "#fff",
            paddingTop: 10,
            marginTop: 25,
          }}
        >
          <Button onClick={this.closeDrawer}>Cancelar</Button>
          <Button
            type="primary"
            style={{ marginLeft: 8 }}
            onClick={this.handleSubmit}
          >
            {data.id ? "Salvar" : "Cadastrar"}
          </Button>
        </div>
        <Modal />
      </Drawer>
    );
  }
}

const mapStateToProps = (state) => ({
  drawer: state.drawerChecklist,
  customers: state.customers.list.filter(row => row.inactived_at === null && row.is_customer).sort((a,b) => compare()(a.cod, b.cod)),
  user: state.user,
  permissions: state.permissions.list,
  checklistTasks: state.checklists.tasks
});

const mapDispatchProps = (dispatch) => ({
  closeDrawer: () => dispatch(drawerChecklistActions.closeDrawer()),
  createChecklist: (data) => dispatch(checklistActions.createChecklist(data)),
  updateChecklist: (data, id) =>
    dispatch(checklistActions.updateChecklist(data, id)),
});

export default connect(
  mapStateToProps,
  mapDispatchProps
)(Form.create()(DrawerChecklist));
