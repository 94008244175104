import { CACHE_ACTIONS } from '~/constants/cache-actions.constants';
import { LocalCacheHandler } from '~/utils/local-cache-handler';

import * as customerActions from '~/_domains/customers/drawer/actions';

const defaultState = {
    list: [],
    isLoading: true
}

const ACTION_HANDLERS = {
    [CACHE_ACTIONS.customers]: (state, action) => {
        return action.payload;
    },

    [CACHE_ACTIONS.procurations]: (state, action) => {
        return {
            ...state,
            isLoading: false,
            list: action.payload.customers
        }
    },

    'LOAD_CUSTOMERS_SUCCESS': (state, action) => {
        return {
            ...state,
            list: action.payload.data,
            isLoading: false
        };
    },

    'REGISTER_CUSTOMER_SUCCESS': (state, action) => {
        return {
            ...state,
            list: [
                ...state.list,
                action.payload.data.data
            ]
        };
    },

    'DELETE_CUSTOMER_SUCCESS': (state, action) => {
        return {
            ...state,
            list: state.list.filter(item => item.id !== action.meta.previousAction.id)
        };
    },

    'SAVE_CUSTOMER_SUCCESS': (state, action) => {
        console.log('SAVE_CUSTOMER_SUCCESS - Action:', action);
        console.log('SAVE_CUSTOMER_SUCCESS - Customer data before update:', 
            state.list.find(item => item.id === action.payload.data.data.id));
        
        const updatedList = state.list.map(item => {
            if (item.id !== action.payload.data.data.id) {
                return item;
            }
            
            // Merge the existing item with the updated data, ensuring we don't lose properties
            const updatedItem = {
                ...item,
                ...action.payload.data.data
            };
            
            console.log('SAVE_CUSTOMER_SUCCESS - Customer after merge:', updatedItem);
            return updatedItem;
        });
        
        return {
            ...state,
            list: updatedList
        };
    },

    'ADD_MONTHLY_HONORARY_SUCECESS': (state, action) => {
        return {
            ...state,
            list: state.list.map(item => item.id !== action.payload.data.data.id? item : action.payload.data.data)
        };
    },

    'INACTIVATE_CUSTOMER_SUCCESS': (state, action) => {
        if(action.payload.data.status==='success'){
            return {
                ...state,
                list: state.list.map(item => item.id !== action.payload.data.data.id? item : action.payload.data.data)
            }
        }else{
            return state;
        }
    },

    'ACTIVATE_CUSTOMER_SUCCESS': (state, action) => {
        if(action.payload.data.status==='success'){
            return {
                ...state,
                list: state.list.map(item => item.id !== action.payload.data.data.id? item : action.payload.data.data)
            }
        }else{
            return state;
        }
    },

    'FORCE_ACTIVATE_CUSTOMER_SUCCESS': (state, action) => {
        return {
            ...state,
            list: state.list.map(item => item.id !== action.payload.data.data.id? item : action.payload.data.data)
        };
    },

    'INACTIVATE_CUSTOMER_REMOVE_TASKS_SUCCESS': (state, action) => {
        return {
            ...state,
            list: state.list.map(item => item.id !== action.payload.data.data.id? item : action.payload.data.data)
        };
    },

    /*
    ** Dashtask
    */
    'REGISTER_DASHTASK_ITEM_SUCCESS': (state, action) => {
        if(parseInt(action.payload.data.data.category) === 1){
            return {
                ...state,
                list: [
                    ...state.list,
                    ...action.payload.data.data.custom_fields
                ]
            };
        }else{
            return state;
        }
    },

    'UPDATE_DASHTASK_ITEM_SUCCESS': (state, action) => {
        if(parseInt(action.payload.data.data.category) === 1){
            return {
                ...state,
                list: state.list.map(item => item.id !== action.payload.data.data.customer_id? item : action.payload.data.data.custom_fields)
            }
        }else{
            return state
        }
    },
}

const reducer = (state = defaultState, action) => {
    const handler = ACTION_HANDLERS[action.type];
    if(!handler) return state;

    const newState = handler(state, action);

    const updateCache = [
        'LOAD_CUSTOMERS_SUCCESS',
        'REGISTER_CUSTOMER_SUCCESS',
        'DELETE_CUSTOMER_SUCCESS',
        'SAVE_CUSTOMER_SUCCESS',
        'ADD_MONTHLY_HONORARY_SUCECESS',
        'INACTIVATE_CUSTOMER_SUCCESS',
        'ACTIVATE_CUSTOMER_SUCCESS',
        'FORCE_ACTIVATE_CUSTOMER_SUCCESS',
        'INACTIVATE_CUSTOMER_REMOVE_TASKS_SUCCESS',
        'REGISTER_DASHTASK_ITEM_SUCCESS',
        'UPDATE_DASHTASK_ITEM_SUCCESS',
    ].includes(action.type);

    if(updateCache){
        LocalCacheHandler.save({
            pathname: CACHE_ACTIONS.customers,
            data: newState
        });
    }

    return newState;
}

export default reducer;