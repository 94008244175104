/* eslint-disable default-case */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Table, Tag, Icon, Dropdown, Menu, Modal, Button, Input, Select } from 'antd';
import moment from 'moment';
import { compare } from 'natural-orderby';

import 'moment/locale/pt-br';
import { ModuleHeader } from '../ModuleHeader';
import { HeaderContainer, HeaderLeftContent, HeaderRightContent } from './styles';
import { getDefaultPaginationConfig } from '~/utils/get-default-pagination-config';
import { STORAGE } from '~/constants/storage.constants';

// import Archive from './Includes/Archive';

const { Column } = Table;
const Search = Input.Search;
const { Option } = Select;

const getColorTag = (status) => {
    switch(status){
        case 'Expirado': return '#f5222d';
        case 'Pendente': return '#fadb14';
        case 'Concluido': return '#52c41a';
        default: console.log(`Status: ${status} not supported`);
    } 
}

const getMonthName = (value) => {
    
  switch(value){
      case 1: return 'Janeiro';
      case 2: return 'Fevereiro';
      case 3: return 'Março';
      case 4: return 'Abril';
      case 5: return 'Maio';
      case 6: return 'Junho';
      case 7: return 'julho';
      case 8: return 'Agosto';
      case 9: return 'Setembro';
      case 10: return 'Outubro';
      case 11: return 'Novembro';
      case 12: return 'Dezembro';
      default: console.log(`Month Name: ${value} is not valid`);
  }
}

const years = (back) => {
  const currentyear = new Date().getFullYear();
  return Array.from({length: back}, (v, i) => currentyear+1 - back + i + 1).sort(function(a, b){return b-a});
}

class ConventionsList extends Component {

    state = {
      viewArchived: false,
      view: null,
      search: '',
      archive: null,
      year: moment().format('YYYY')
    }

  onAction = (e, certificate) => {
    switch(e.key){
      case 'edit': 
      this.props.showEdit(certificate);
      break;
      case 'delete': 
        Modal.confirm({
          title: 'Você realmente deseja excluir este certificado?',
          content: 'Esta ação não poderá ser desfeita.',
          okText: 'Sim',
          okType: 'danger',
          cancelText: 'Cancelar',
          onOk: () => this.props.deleteConventions(certificate.id)
        });
      break;
    }
  }

  render() {
    const { year } = this.state;
    const { isLoading } = this.props;

    const conventions = this.props.conventions ? this.props.conventions.map(item => {
      let customer = item.customer_id ? this.props.customers.filter(row => row.id === item.customer_id)[0]: null;

      return ({
        ...item,
        name: (customer !== null && item.nature === 'PJ')? customer.name:item.owner_name,
        customer
        })
      }):[];
    
    return (  
      <div>
        <div style={{ 
            margin: `0px -16px`, 
            padding: '15px', 
            background: '#fff', 
            marginBottom: `10px` }}>
              <ModuleHeader
                breadcrumbs={['Tarefas', 'Convenções coletiva']}
                title="Convenções Coletivas"
                search={
                  <Search 
                    placeholder="Pesquisar convençōes" 
                    onChange={e => this.setState({search: e.target.value})}
                    style={{ maxWidth: 300 }}
                  />
                }
                actions={
                  <>
                    <Select
                      value={year}
                      placeholder="Selecione um ano"
                      onChange={(value) => {
                        this.setState({year: value});
                        this.props.loadConventions(value);
                      }}
                    >
                      {years(parseInt(moment().format('YYYY'))-2016).map(row  => 
                      <Option value={row} key={row}>{row}</Option>
                      )}
                    </Select>
                    <Button type="primary" onClick={this.props.showModal}>
                        <Icon type="plus" /> Convenção Coletiva
                    </Button>
                  </>
                }
              />
        </div>
        <div className="table-clabs">
          <Table 
            rowKey="id"
            // dataSource={(viewArchived?archived:unArchived)} 
            loading={isLoading}
            dataSource={conventions} 
            pagination={getDefaultPaginationConfig({
              storageKey: STORAGE.defaultPageSize.conventions,
              defaultPageSize: 1000
            })}
            bordered={true}           
            scroll={{ x: 500, y: `${window.innerHeight-175}px`}}
          >
            <Column
                title="Status"
                dataIndex="status_text"
                key="status_text"               
                width={150}
                filters={[{
                    text: 'Concluído',
                    value: 'Concluído',
                  }, {
                    text: 'Pendente',
                    value: 'Pendente',
                  },
                  {
                    text: 'Expirado',
                    value: 'Expirado',
                  }
                ]}
                filterMultiple={false}
                onFilter={(value, record) => record.status.indexOf(value) >= 0}
                render={status_text => <center><Tag color={getColorTag(status_text)} key={status_text}>{status_text}</Tag></center>}
              />            

               <Column
                  title="Nome"
                  dataIndex="owner_name"
                  key="owner_name"                 
                  width={250}
                  sorter={(a, b) => a.name && a.name.localeCompare(b.name)}
              />

              <Column
                  title="Data Base"
                  dataIndex="date"
                  key="date"                 
                  width={200}
                  render={((date, record) => `${getMonthName(date)}/${record.year_base}`)}
              />   
              <Column
                  title="Qtd de Clientes"
                  dataIndex="qty_customers"
                  key="qty_customers"                  
                  width={200}                 
                  sorter={(a, b) => a.qty_customers && (a.qty_customers-b.qty_customers)}
                  sortDirections={['descend', 'ascend']}                  
              />     
              <Column
                  title="Meses pra vencer"
                  dataIndex="months_to_expire"
                  key="months_to_expire"                  
                  width={200}                 
                  sorter={(a, b) => a.qty_customers && (a.qty_customers-b.qty_customers)}
                  sortDirections={['descend', 'ascend']}                  
              />                    
              <Column
                  dataIndex="actions"
                  key="actions"                  
                  width={100}
                  render={(text, record, index) => (
                  <center key={index}>
                      <Dropdown overlay={
                          <Menu onClick={(e) => this.onAction(e, record)}>
                          <Menu.Item key="edit">
                              <Icon type="edit"></Icon> Editar
                          </Menu.Item>
                          <Menu.Divider />
                          {/* {record.status !=='Arquivado'?
                          <Menu.Item key="archive"><Icon type="inbox"></Icon> Arquivar</Menu.Item>
                          :
                          <Menu.Item key="unArchive"><Icon type="inbox"></Icon> Desarquivar</Menu.Item>} */}
                          <Menu.Item key="delete"><Icon type="delete"></Icon> Excluir</Menu.Item>
                          </Menu>
                      } trigger={['click']}>
                          <a className="ant-dropdown-link" href="#">
                          Ações <Icon type="down" />
                          </a>
                      </Dropdown>
                  </center>
              )}
              />
          </Table>
        </div>
      </div>
      )
  }
}

const mapStateToProps = state => {
  return{
    conventions: state.conventions.list,
    isLoading: state.conventions.isLoading,
    customers: state.customers.list.filter(row => row.enabled && row.inactived_at === null && row.is_customer).sort((a,b) => compare()(a.cod, b.cod)),
    findUser: (id) => state.users.filter(r=>r.id===id)[0],
  }
}

const mapDispatchProps = dispatch => {
  return{
    loadConventions: (year) => dispatch({
      type: 'LOAD_CONVENTIONS',
      payload: {
        request:{
          method: 'get',
          url:`/conventions/year/${year}`
        }
      }
    }),
    deleteConventions: (id) => dispatch({
      type: 'DELETE_CONVENTION',
      payload: {
        request:{
          method: 'delete',
          url:'/conventions/'+id,
        }
      },
      id: id
    }),
    unArchive: (id) => dispatch({
      type: 'SAVE_CONVENTION',
      payload: {
        request:{
          method: 'put',
          url:'/conventions/'+id+'/unarchive',
        }
      },
      id: id
    }),
    saveConventions: (data) => dispatch({
      type: 'SAVE_CONVENTION_SUCCESS',
      payload: data
    })
  }
}

export default connect(mapStateToProps, mapDispatchProps)(ConventionsList);